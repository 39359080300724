import React from 'react';
import classes from './Spinner.module.css'
const spinner = (props) => {
	let color = "#FFFFFF"
	if(props.alt) {
		color = "#FF4F4F"
	}
	return(
		<div className = {classes.Spinner} style = {{color:color}}>Loading...</div>
	);
}
export default spinner;		