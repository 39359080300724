import React from 'react';
import classes from './ReservationManagement.module.css'
import ReservationCard from './ReservationCard/ReservationCard'
import Logo from '../../../components/UI/Logo/Logo'
import ReservationManagementModal from './ReservationManagementModal/ReservationManagementModal'
export default class PLACEHOLDER extends React.Component{
 //creating fake dates to populate array
 dateOne = new Date();
 dateTwo = new Date(this.dateOne.getTime() + (.5*60*60*1000));
 dateThree = new Date(this.dateTwo.getTime() + (1*60*60*1000));
 dateFour = new Date(this.dateThree.getTime() + (25*60*60*1000));
 dateFour = new Date(this.dateThree.getTime() + (25*60*60*1000));
 //placeholder array to test data passing...

state = {
 	showModal : false,
 	userClicked : null,
 	reservations: [
	 	{
	 		name: "Thomas Train",
	 		time: this.dateOne,
	 		size: 1,
	 		hidden: false,
	 		noShow: false,
	 		key: 0,
	 		modified: new Date(0)
	 	},
	 	{
	 		name: "Tim Smith",
	 		time: this.dateTwo,
	 		size: 5,
	 		hidden: false,
	 		noShow: false,
	 		key: 4,
	 		modified: new Date(0)
	 	},
	 	{
	 		name: "Jim Reed",
	 		time: this.dateTwo,
	 		size: 5,
	 		hidden: false,
	 		noShow: false,
	 		key: 1,
	 		modified: new Date(0)
	 	},
	 	{
	 		name: "Jane Steen",
	 		time: this.dateThree,
	 		size: 2,
	 		hidden: false,
	 		noShow: false,
	 		key: 2,
	 		modified: new Date(0)
	 	},
	 	{
	 		name: "Tony Christiansen",
	 		time: this.dateFour,
	 		size: 5,
	 		hidden: false,
	 		noShow: false,
	 		key: 3,
	 		modified: new Date(0)
	 	}
	]
}
//removes user when checked in
checkInClickedHandler = (key) => {
	console.log(key)
	let reservations = this.state.reservations
	let index = reservations.findIndex(res => {
		console.log(res)
		return(res.key === key);
	})
	let user = reservations[index];
	user.hidden = true;
	user.modified = new Date();
	reservations[index] = user;
	this.setState({
		reservations:reservations,
		showModal: false,
	})
}
//when no show is clicked
noShowClickedHandler = (key) => {
	let reservations = this.state.reservations
	let index = reservations.findIndex(res => {
		return(res.key === key);
	});
	let user = reservations[index];
	user.modified = new Date();
	user.noShow = true;
	user.hidden = true;
	reservations[index] = user;
	this.setState({
		reservations:reservations,
		showModal: false,
	})

}
//when modal is closed
closeClickedHandler = () => {
 	this.setState({
 		showModal: false,
 	})
 }
//when three dots are clicked
dotsClickedHandler = (key) => {
	let reservations = this.state.reservations
	let user = reservations.find(res => {
		return(res.key === key);
	});
	console.log(user);
	this.setState({
		userClicked: user,
		showModal:true,
	})
}
//when three dots are clicked
undoClickedHandler = (key) => {
	let reservations = this.state.reservations;
	let index = reservations.findIndex(res => {
		return(res.key === key);
	});
	let user = reservations[index];
	user.hidden = false;
	user.noShow = false;
	reservations[index] = user;
	this.setState({
		reservations:reservations
	})
}
 //determines whether two times are less than an hour apart
	lessThanOneHour = (date, now) => {
 		const oneHour = 60 * 60 * 1000;
 		const fifteen = 15*60*1000;
 		if((date - now) <= oneHour || Math.abs(now - date) <= fifteen) {
 			return(true);
 		} else {
 			return(false);
 		}
 	}
 //determines if two times are the same day
	sameDay = (date, now) => {
		if(date.getFullYear() === now.getFullYear() &&
		  date.getMonth() === now.getMonth() &&
		  date.getDate() === now.getDate()) {
		return(true);
		} else {
			return(false);
		}
	}
 //determines if two times are within the same week
	nextWeek = (date, now) => {
 		const oneWeek = 7*24 * 60 * 60 * 1000;
		if((date - now) <= oneWeek) {
			return(true);
		} else {
			return(false);
		}
 	}
 	//calls all the comparison functions and buckets the reservations by time
	dateSorter = (dates, checkInDates) => {
		let sorter = {
			nextHour: [],
			sameDay: [],
			nextWeek: [],
			later: [],
			checkedIn: []
		}
		let checkIn = {

		}
		const now = new Date();
		//looping through reservations
		dates.forEach(res => {
			if(this.lessThanOneHour(res.time, now)) {
				sorter.nextHour.push(res);
			} else if (this.sameDay(res.time, now)) {
				sorter.sameDay.push(res);
			} else if (this.nextWeek(res.time,now)){
				sorter.nextWeek.push(res);
			} else {
				sorter.later.push(res);
			}
		});
		checkInDates.forEach(res => {
			if(this.sameDay(res.modified, now)) {
				sorter.checkedIn.push(res);
			}
		})
			return(sorter);
	}
 render(){
 	let reservations = this.state.reservations
 	//shallow copy for other sorting 
 	let checkedIn = [...reservations]
 	//bucketed reservations (and sorting by time)
 	const sortedReservations = this.dateSorter(reservations.sort((a, b) => a.time - b.time), checkedIn.sort((a, b) => b.modified - a.modified));
 	//reservations occuring in next hour
 	const nextHourCards = sortedReservations.nextHour.map(res => {
 		return(
 			<ReservationCard
 				{...res} 
 				id = {res.key}
 				dotsClicked = {this.dotsClickedHandler}
 				checkInClicked = {this.checkInClickedHandler}
			/>
		);
 	})
 	//reservations for same day
 	const sameDayCards = sortedReservations.sameDay.map(res => {
 		return(
 			<ReservationCard
 				{...res} 
 				id = {res.key}
 				dotsClicked = {this.dotsClickedHandler}
 				checkInClicked = {this.checkInClickedHandler}
			/>
		);
 	})
 	//reservations in the next week
 	const nextWeekCards = sortedReservations.nextWeek.map(res => {
 		return(
 			<ReservationCard
 				{...res} 
 				id = {res.key}
 				dotsClicked = {this.dotsClickedHandler}
 				checkInClicked = {this.checkInClickedHandler}
			/>
		);
 	})
 	const checkedInCards = sortedReservations.checkedIn.map(res => {
 		return(
 			<ReservationCard
 				{...res} 
 				id = {res.key}
 				dotsClicked = {this.dotsClickedHandler}
 				checkInClicked = {this.checkInClickedHandler}
 				checkedIn
 				undoClicked = {this.undoClickedHandler}
			/>
		);
 	})
  return(
  <React.Fragment>
	  <Logo alt/>
		{/*Modal when a three dots is clicked*/}
	  <ReservationManagementModal
			showModal = {this.state.showModal}
			closeClicked = {this.closeClickedHandler}
			checkInClicked = {this.checkInClickedHandler}
			noShowClicked = {this.noShowClickedHandler}
			{...this.state.userClicked}
			id = {this.state.userClicked ? this.state.userClicked.key : 0}
		/>
		{/*different time buckets*/}
	  <div className = {classes.CardGroup}>
		  <h1>Next Hour</h1>
		  <div className = {classes.ReservationCards}>
		  	{nextHourCards}
		  </div>
	  </div>
	  <div className = {classes.CardGroup}>
		  <h1>Later Today</h1>
		  <div className = {classes.ReservationCards}>
		  	{sameDayCards}
		  </div>
	  </div>
	  <div className = {classes.CardGroup}>
		  <h1>Next Seven Days</h1>
		  <div className = {classes.ReservationCards}>
		  	{nextWeekCards}
		  </div>
	  </div>
	  <div className = {classes.CardGroup} style = {{marginBottom: "20px"}}>
		  <h1>Checked In Today</h1>
		  <div className = {classes.ReservationCards}>
		  	{checkedInCards}
		  </div>
	  </div>
  </React.Fragment>
  );
 }
}