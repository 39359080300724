import React from 'react';
import firebase from 'firebase';
import classes from './ReservationForm.module.css';
import DropDown from '../../../../components/UI/DropDown/DropDown';
import Button from '../../../../components/UI/Buttons/Button';
import TextField from '../../../../components/UI/TextField/TextField';
import DatePicker from 'react-datepicker';
import BackArrow from '../../../../Assets/Back/BackArrow';
import Spinner from '../../../../Assets/Spinner/Spinner';
import Phone from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import "react-datepicker/dist/react-datepicker.css";
import {createBooking} from '../../../../helpers/db';
import {db} from '../../../../services/firebase';

export default class ReservationForm extends React.Component {
  constructor(props) {
    super(props);
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    this.state = {
      date: date,
      times: [],
      time: null,
      numPeople: 1,
      unavailableDates: [],
      nextButtonDisabled: true,
      bookButtonDisabled: true,
      minutes: 30,
      firstPage : 'true',
      phone:'',
      calOpen: false,
      submitting: false,
      done: false,
    }
  }
  async getUnavailable(numPeople) {
    var getUnavailableDays = firebase.functions().httpsCallable('getUnavailableDays');
    let storeData = this.props.store.data();
    storeData['id'] = this.props.store.id;
    let result = await getUnavailableDays({store: storeData, numPeople: numPeople || this.state.numPeople})
    return result.data.map((time) => {
      return new Date(time);
    });
  }
	async componentDidMount() {
    this.setState({
      unavailableDates: await this.getUnavailable(1)
    });
    let storeData = this.props.store.data();
    storeData['id'] = this.props.store.id;
    var getAvailableTimes = firebase.functions().httpsCallable('getAvailableTimes');
    getAvailableTimes({store: storeData, date: this.state.date.getTime()}).then((result) => {
      let times = Object.keys(result.data);
      this.setState({
      	times: result.data,
      	time: times.length > 0 ? parseFloat(times[0]) : null,
      	nextButtonDisabled: false
      });
    });
	}
	submitClickedHandler = () => {
		this.setState({
			submitting: true,
		});

    createBooking(this.state.time, this.state.numPeople, this.props.store.ref, this.state.date, this.state.phone, this.state.email).then(() => {
      this.setState({
        submitting: false,
        done: true
      });
    });
	}
	nextClickedHandler = () => {
		this.setState({
			firstPage: false,
		})
	}
	//Converts int value to corresponding month 0 = january, 11 = december
	toMonth = (month) => {
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		return(months[month]);
	}
	//when back button is clicked
	backClickedHandler = () => {
		this.setState({
			firstPage: true,
		})
	}
	//called when date picker is changed
	datePickerChangedHandler = (value) => {
		console.log(value);
    let storeData = this.props.store.data();
    storeData['id'] = this.props.store.id;
    var getAvailableTimes = firebase.functions().httpsCallable('getAvailableTimes');
    //making times an empty array while loading new times
    //done to trigger loading mode on dropdown
    this.setState({
    	times:[],
    	nextButtonDisabled: true,
    })
    getAvailableTimes({store: storeData, date: value.getTime()}).then((result) => {
      let times = Object.keys(result.data);
      this.setState({
      	times: result.data,
      	time: times.length > 0 ? parseFloat(times[0]) : null,
      	nextButtonDisabled: false});
    });
		this.setState({
			date: value,
		})
	}

	calOpenHandler = () => {
		this.setState({
			calOpen: true,
		})
	}
	calClosedHandler = () => {
		this.setState({
			calOpen: false,
		})
	}
	phoneChangedHandler = (number) => {
		console.log(number.length);
		this.setState({
			phone:number,
		})
		if (number.length == 10) {
			this.setState({
				bookButtonDisabled:false
			})
		} else {
			this.setState({
				bookButtonDisabled:true
			})
		}
	}

  peopleChange = async (e) => {
    let newPeople = parseInt(e.target.value);
    let unavailableDates = await this.getUnavailable(newPeople);
    this.setState({numPeople: newPeople, unavailableDates: unavailableDates});
  };

  timeChange = (e) => {
    this.setState({time: parseFloat(e.target.value)});
  }

  //style for phone field
  inputStyle = {
		border: "0",
		background: "transparent",
		borderBottomWidth: "2px",
		borderStyle: "solid",
		borderColor: "#FF4F4F",
		color: "#FF4F4F",
		fontSize: "1.5em",
		transition: "all .5s easeOut",
		WebkitBorderRadius: "0px",
  	WebkitAppearance: "none",
  	paddingLeft: "10px",
		MozAppearance: "none",
		msAppearance: "none",
		OAppearance: "none",
		appearance: "none",
		paddingTop:"0px",
		paddingBottom:"0px",
		maxWidth: "80%",
		textAlign:"center",
	}
	//removing phone flag button (for now)
	buttonStyle = {
		visibility:"hidden"
	}
	//formats time from float 7.5 -> 7:30 AM
	formatTime = (time) => {
		let hour = ((Math.floor(time)-1) % 12) + 1;
    let minute = (time - Math.floor(time)) * 60;
    minute = minute < 10 ? `0${minute}` : minute;
    let am = time < 12;
    return(`${hour}:${minute} ${am ? 'AM' : 'PM'}`);
	}
	render(){
		//suspends overflow when calendar is closed for animation
		let hidden = this.state.calOpen ? 'visible' : 'hidden'

    let store = this.props.store.data();

    let peopleOptions = [];
    for (var i = 1; i <= Math.min(3, store.maxPeople); i++) {
      peopleOptions.push([i, `${i} ${i == 1 ? "Person" : "People"}`]);
    }
		
		let timesOptions = [];
		
		//if times are loading, then shows it in dropdown
    if(this.state.times.length === 0) {
    	timesOptions = ['Loading Times...']
    }
    
    for (let [key, value] of Object.entries(this.state.times)) {
      if (value - this.state.numPeople >= 0) {
        timesOptions.push([parseFloat(key), this.formatTime(key)]);
      }
    }
    timesOptions.sort((a, b) => {return a[0] - b[0]});
    let body = (
    	<React.Fragment>
	    	<div style = {{
						transform: this.state.firstPage ? 'translateX(0)' : 'translateX(-100vw)',
					}}>
			  		<h3>Make a reservation</h3>
			  		<p>Reservations are for {this.state.minutes} minutes</p>
			  		{/*one row selection*/}
			  		<div className = {classes.Persons}>
			  			<p>For </p><DropDown alt options = {peopleOptions} value={this.state.numPeople} onChange={this.peopleChange} />
			  		</div>
			  		<br/>
			  		<DatePicker
			  			onCalendarOpen = {this.calOpenHandler}
			  			onCalendarClose = {this.calClosedHandler}
			  			minDate = {new Date()}
			  			onChange = {this.datePickerChangedHandler}
			  			selected = {this.state.date}
			  			className = {[classes.TextField, classes.Alt].join(' ')}
			  			excludeDates = {this.state.unavailableDates}
			  			popperPlacement = {"top"}
			  			popperModifiers={{
				        flip: {
				            behavior: ["top"] // forces top flip
				        },
				        preventOverflow: {
				            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
				        },
				        hide: {
				            enabled: false // turn off since needs preventOverflow to be enabled
				        }
    					}}
		  			/>
		  			<div style = {{marginTop: "10px"}}>
			  			<DropDown alt options = {timesOptions} value={this.state.time} onChange={this.timeChange} />
		  			</div>
			  		<br/>
			  		<Button 
			  			alt
			  			disabled = {this.state.nextButtonDisabled}
			  			onClick = {this.nextClickedHandler}
		  			>
			  			Next
		  			</Button>
	  			</div>
	  			<div style = {{
	  				position: "absolute",
	  				top: "40px",
	  				left: "50%",
	  				width:"100%",
						transform: this.state.firstPage ? 'translateX(100vw)' : 'translateX(-50%)',
					}}>
            {/*below is hidden until next button pressed
            position is absolute due to the fact that parent div
            would be sized incorrectly*/}
            <BackArrow
                style = {{
                  position: "absolute",
                  top: "-30px",
                  left: "10px",
                  transform: this.state.firstPage ? 'translateX(100vw)' : 'translateX(0)',
                }}
                onClick = {this.backClickedHandler}
              />
			  		<h3>Your reservation</h3>
			  		<p>{this.state.numPeople === 1 ? "1 person" : [this.state.numPeople, "people"].join(" ")} for {this.toMonth(this.state.date.getMonth())} {this.state.date.getDate()} at {this.formatTime(this.state.time)}</p>
			  		<Phone
			  			country={'us'}
			  			onlyCountries = {['us']}
  						value={this.state.phone}
  						placeholder = "(123) 456-7890"
  						onChange={(number) => this.phoneChangedHandler(number)}
			  			disableCountryCode = {true}
			  			disableDropdown = {true}
			  			disableSearchIcon = {true}
			  			inputStyle = {this.inputStyle}
			  			buttonStyle = {this.buttonStyle}
			  			/>
			  		<br/>
			  		<div
			  			style = {{margin:"20px"}}
		  			>
				  		<Button alt
				  			disabled = {this.state.bookButtonDisabled}
				  			onClick = {this.submitClickedHandler}>
				  			Book
			  			</Button>
		  			</div>
	  			</div>
  			</React.Fragment>);
    if(this.state.submitting) {
    	body = <Spinner alt/>
    }
    if(this.state.done) {
    	body = (
    		<React.Fragment>
	    		<h3>Your reservation is confirmed</h3>
	    		<p>You will be texted a reminder one hour before your appointment</p>
  			</React.Fragment>
  		);
    } 
		return(
			<div
				className = {classes.ReservationForm}
				style = {{overflow: hidden}}
				>
				{body}
		 	</div>
		);
	}
}
