import React from 'react';
import Button from '../../../../components/UI/Buttons/Button';
import TextField from '../../../../components/UI/TextField/TextField'
import classes from './ShopperBrowseModal.module.css'
import Modal from '../../../../components/UI/Modal/Modal';
const shopperBrowseModal = (props) => {
	return(
		<Modal show = {props.showModal}>
			<div className = {classes.Modal}>
				<h1> Where do you want to shop?</h1>
				<p> Just for demonstration purposes, stores listed are not real </p>
				<TextField
					type = "text"
					placeholder = "Enter a nearby zip"
					onChange = {props.cityChangeHandler}/>
				<br/>
				<Button
					onClick = {props.getStartedClickHandler}
					disabled = {props.submitDisabled}
				>
					Get Started
				</Button>
			</div>
		</Modal>
	);
}
export default shopperBrowseModal;