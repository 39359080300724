import React from 'react';
import classes from './TextField.module.css'

//alt = alternative color (red)
//disabled disables the form
const textField = (props) => {
	let classArr = [classes.TextField];
	if (props.alt) {
		classArr = [classes.TextField, classes.Alt]
	}
	if (props.inset) {
		classArr = [classes.TextField, classes.Alt, classes.Inset]
	}
	return(
		<input
			disabled = {props.disabled}
			type = {props.type}
			className = {classArr.join(' ')}
			placeholder = {props.placeholder}
			onChange = {props.onChange}
			value = {props.value}
			pattern = {props.pattern}
			required = {props.required}
		/>
	);
}
export default textField;