import React from 'react';
import TextField from '../../../../components/UI/TextField/TextField';
import Button from '../../../../components/UI/Buttons/Button';
import Spinner from '../../../../Assets/Spinner/Spinner';
import classes from './SignupForm.module.css';
import {login, signup} from '../../../../helpers/auth';
import {createStore, addUserToStore, createBooking, createShopper} from '../../../../helpers/db';
import DropDown from '../../../../components/UI/DropDown/DropDown';
import Aux from '../../../../hoc/Aux'
class SignupForm extends React.Component{
  state = {
    email: '',
    city: '',
    storeName: '',
    enableSubmit: false,
    error: '',
    isStore: true,
    submitTried : false,
    agreeCheck : false,
  }
  //two types of users
  options = ['store', 'shopper'];
  //would send email to server here
  submitClickHandler = async (e) => {
      this.setState({
        submitTried:true,
      })
      e.preventDefault();

      try {
        //Defaut password is setup for everyone for now because we don't have login.
        let auth = await signup(this.state.email, "123456");
        if(this.state.isStore) {
          await createStore(this.state.storeName, auth.user.uid, this.state.city);
          this.setState({ signedUp: true});
        } else {
          await createShopper(this.state.email, auth.user.uid, this.state.city);
          this.setState({ signedUp: true});
        }

      } catch (error) {
        this.setState({ error: error.message });
      }
	};
  //checks email, zip, storeName before enabling form submission
  checkProperForms = (email, zip, name) => {
    //regex for email
    let emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //regex for zipcode
    let zipRe = /^[0-9]{5}(?:-[0-9]{4})?$/;
    //checking what type of user this is
    if(this.state.isStore){
      //store
      if ( emailRe.test(email) && zipRe.test(zip) && !(name.trim() === "") && this.state.agreeCheck) {
        //valid
        this.setState({
          enableSubmit:true,
        });
      } else {
        //invalid email, zip or name
        this.setState({
          enableSubmit:false,
        });
      }
    } else {
      //shopper
      if ( emailRe.test(email) && zipRe.test(zip) && this.state.agreeCheck) {
        //valid
        this.setState({
          enableSubmit:true,
        });
      } else {
        //invalid email, zip or name
        this.setState({
          enableSubmit:false,
        });
      }
    }
  }

  //sets email state
  emailChangeHandler = (event) => {
    this.setState({
      email: event.target.value
    });
    this.checkProperForms(event.target.value, this.state.city, this.state.storeName);
  }

  //sets city state
  cityChangeHandler = (event) => {
    this.setState({
        city:event.target.value,
      });
    this.checkProperForms(this.state.email, event.target.value , this.state.storeName);
  }

  //sets storeName
  nameChangeHandler = (event) => {
    this.setState({
        storeName:event.target.value,
      });
    this.checkProperForms(this.state.email, this.state.city, event.target.value, );
  }

  changeSelectHandler = () => {
    this.setState((state, props) => ({
      isStore:!state.isStore,
    }));
  }

  checkBoxChangedHandler = () => {
    this.setState((state, props) => ({
      agreeCheck: !state.agreeCheck
    }), () => this.checkProperForms(this.state.email, this.state.city, this.state.storeName));
    
  }
  render() {
    let classList = [];

    //checking for colorscheme here
    if (this.props.alt) {
      //use alternate
      classList = [classes.Form, classes.Alt].join(' ');
    } else {
      //stick with original
      classList = [classes.Form].join(' ');
    }
    //determining whether spinner or form body is shown
    let body = (
      <span>
        <h2> Signup for Updates</h2>
        <div className = {classes.DropDown}>
          <p>I am a</p><DropDown alt = {this.props.alt} onChange = {this.changeSelectHandler} options = {this.options}/>
        </div>
        <br/>
          {/*configuring alt or regular, all text input*/}
          <TextField disabled = {!this.state.isStore} alt = {this.props.alt} type = "text" placeholder = "Store Name" onChange = {this.nameChangeHandler}/>
          <TextField alt = {this.props.alt} type = "email" placeholder = "Email" onChange = {this.emailChangeHandler}/>
          <TextField alt = {this.props.alt} type = "text" placeholder = "Zip Code" onChange = {this.cityChangeHandler}/>
          <br/>
          <div style = {{ whiteSpace: "nowrap", overflowX:"auto"}}>
            <input
              type = "checkbox"
              style = {{display: "inline-block"}}
              onChange = {this.checkBoxChangedHandler}
              checked ={this.state.agreeCheck}/>
            <p style = {{display: "inline-block",fontSize : ".75em", margin: "0px", marginTop:"20px", marginLeft: "10px", padding: "0px"}}>
              Receive updates from Spaced Shopping
            </p>
          </div>
        {this.state.error &&
          <p className="error">
          {this.state.error}
          </p>
        }
        <br/>
        <Button
          onClick = {this.submitClickHandler}
          disabled = {!this.state.enableSubmit}
          alt = {this.props.alt}
        >
          Submit
        </Button>
      </span>
    );
    //if submit has been pressed
    if(this.state.submitTried) {
      body = <Spinner alt = {this.props.alt}/>
    }
  	return(
    		<div className = {classList}>
          {!this.state.signedUp
            ?
            body
            : <span>
                <p style = {{textAlign : "center"}}>
                Thank you for expressing interest. Stay tuned for updates.
                </p>
              </span>
          }
        </div>
    );
  };
}
export default SignupForm;
