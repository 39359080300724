import firebase from 'firebase';
import { db } from '../services/firebase';

export async function createStore(name, uid, zip) {
  return new Promise((resolve, reject) => {
    db.collection("InterestedStores").add({
      name: name,
      employees: [uid],
      zip: zip || null
    })
    .then((docRef) => {
      resolve(docRef);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export async function listStores(zip, search) {
  return new Promise((resolve, reject) => {
    let x = db.collection("stores");
    if (zip) x = x.where('zip', '==', zip);
    if (search !== undefined) x = x.orderBy('searchName').startAt(search).endAt(search + '\uf8ff');
    x.get().then((snapshot) => {
      let stores = []
      //resolve(snapshot
      snapshot.forEach((doc) => {
        stores.push(doc);
      });
      resolve(stores);
    });
  });
}

export async function fetchStore(id) {
  return new Promise((resolve, reject) => {
    db.collection("stores").doc(id).get().then((doc) => {
      if (doc.exists) {
        resolve(doc);
      } else {
        reject(404);
      }
    });
  });
}

export async function createShopper(email, uid, zip) {
  return new Promise((resolve, reject) => {
    db.collection("shoppers").add({
      uid:uid,
      email: email,
      zip: zip
    })
    .then((docRef) => {
      resolve(docRef);
    })
    .catch((error) => {
      reject(error);
    });
  });
}


export async function addUserToStore(uid, storeRef) {
  return new Promise((resolve, reject) => {
    storeRef.update({
      employees: firebase.firestore.FieldValue.arrayUnion(uid),
    })
    .then(() => {
      resolve();
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export async function createBooking(startTime, numPeople, storeRef, date, phone, email) {
  return new Promise((resolve, reject) => {
    storeRef.collection("bookings").add({
      startTime: startTime,
      date: date,
      email: email || "",
      phone: phone || "",
      numPeople: numPeople
    })
    .then((docRef) => {
      resolve(docRef);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
