import React from 'react';
import Aux from '../hoc/Aux';
import FrontPage from './Pages/FrontPage/FrontPage';
import LearnMore from './Pages/LearnMore/LearnMore';
import ShopperBrowse from './Pages/ShopperBrowse/ShopperBrowse';
import StorePage from './Pages/StorePage/StorePage';
import ReservationManagement from './Pages/ReservationManagement/ReservationManagement'
import{Route, Switch, Redirect} from 'react-router-dom';
const layout = (props) => {
	return(
		<Aux>
			<Switch>
	      <Route path = "/" exact component = {FrontPage}/>
	      <Route path = "/learn-more" exact component = {LearnMore}/>
	      <Route path = "/places" exact component = {ShopperBrowse}/>
	      <Route path = "/demo" exact component = {ReservationManagement}/>
	      <Route path = "/places/:zip" component = {ShopperBrowse}/>
	      <Route path = "/stores/:id" component = {StorePage}/>
	      <Route path = "/404" render = {() => <h1>Oops, one of use made a mistake!</h1>}/>
	      <Redirect path = "/" to = "/404"/>
      </Switch>
		</Aux>
	);
}
export default layout;