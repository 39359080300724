import React from 'react';
import classes from './ReservationCard.module.css'
import Dots from './buttons/Dots/Dots'
import Check from './buttons/Check/Check'
import Undo from './buttons/Undo/Undo'
const reservationCard = (props) => {
	
	//converts date to AMPM time format
	// https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
	const formatAMPM = (date) =>{
	  var hours = date.getHours();
	  var minutes = date.getMinutes();
	  var ampm = hours >= 12 ? 'pm' : 'am';
	  hours = hours % 12;
	  hours = hours ? hours : 12; // the hour '0' should be '12'
	  minutes = minutes < 10 ? '0'+minutes : minutes;
	  var strTime = hours + ':' + minutes + ' ' + ampm;
	  return strTime;
	}
	//converts date object to formatted time
	const toDOW = (dow) => {
		const weekday = new Array(7);
		weekday[0] = "Sun.";
		weekday[1] = "Mon.";
		weekday[2] = "Tue.";
		weekday[3] = "Wed.";
		weekday[4] = "Thu.";
		weekday[5] = "Fri.";
		weekday[6] = "Sat.";
		return(weekday[dow])
	}
	//formatting time
	let time =toDOW(props.time.getDay())+" "+formatAMPM(props.time);
	
	//styling for animations
	let styling = [classes.ReservationCard]
	if(!props.checkedIn) {
		if (props.hidden) {
			styling.push(classes.Hidden);
		}
	} else {
		if(props.hidden) {
			styling.push(classes.CheckedIn);
		} else {
			styling.push(classes.Hidden);
		}
		if(props.noShow) {
			styling.push(classes.NoShow)
		}
	}
	
	return(
		<div className = {styling.join(' ')}>
			<div className = {classes.Info}>
				<h3>{props.name.split(" ")[0]}</h3>
				<p>{time}</p>
			</div>
			<div className = {classes.Buttons}>
				<Dots onClick = {() => props.dotsClicked(props.id)}/>
				{props.checkedIn ? <Undo onClick = {() => props.undoClicked(props.id)}/>: <Check onClick = {() => props.checkInClicked(props.id)}/>}
			</div>
		</div>
	);
}
export default reservationCard;