import React from 'react';
import StoreCards from '../../../components/StoreCards/StoreCards';
import classes from './ShopperBrowse.module.css';
import ShopperBrowseModal from './ShopperBrowseModal/ShopperBrowseModal';
import TextField from '../../../components/UI/TextField/TextField';
import Logo from '../../../components/UI/Logo/Logo';
import Button from '../../../components/UI/Buttons/Button';
import {Route} from 'react-router-dom'
import {listStores} from '../../../helpers/db';

export default class ShopperBrowse extends React.Component{
	state = {
		//displays zip modal (needs to be on for production)
		showModal : true,
		zip: '',
    search: '',
    stores: [],
		submitDisabled: true,
		loading: true,
	};
	
	async componentDidMount() {
		window.scrollTo(0, 0);
		let zip = this.props.match.params.zip
		if (zip === undefined) {
			console.log("nada");
		} else {
			//zip entered
			//We don't need modal
			this.setState({
				showModal:false,
			});
      this.updateStores();
		}
	}

  updateStores = async () => {
    this.setState({
      loading: true
    });
    // zip undefined for now because we don't want to actually
    // filter by zip yet
    listStores(undefined, this.state.search).then((stores) => {
      console.log("loaded");
      this.setState({
        loading: false,
        stores: stores
      });
    });
  }

	//checks to make sure zip is proper
	cityChangeHandler = (event) => {
		this.setState({
			zip:event.target.value,
		});
		//regex for zipcode
    let zipRe = /^[0-9]{5}(?:-[0-9]{4})?$/;

		if(zipRe.test(event.target.value)) {
			this.setState({
				submitDisabled:false
			});
		} else {
			this.setState({
				submitDisabled:true
			});
		}
	}

	//new zip is pressed
	newZipHandler = () => {
		//launch the modal
		this.setState({
			showModal:true,
		})
	}

	//get started is clicked
  //we don't need the modal
 	getStartedClickHandler = async () => {
		this.setState({
			showModal:false,
		})
		//checking if the zip changed
		if(this.state.zip != this.props.match.params.zip) {
      this.updateStores();
			//if it is, change the URL 
			this.props.history.push(("/places/"+this.state.zip));
		}
	}

  updateSearch = async (e) => {
    this.setState({search: e.target.value.toLowerCase()}, this.updateStores);
  }

 	render() {
    let stores = this.state.stores;
    
	  return(
	  	<React.Fragment>
	  		<Logo alt = {true}/>
				<div className = {classes.ShopperBrowse}>
					<Button
						alt = {true}
						onClick = {this.newZipHandler}>New Zip</Button>
					<ShopperBrowseModal
						showModal = {this.state.showModal}
						cityChangeHandler = {(event) => this.cityChangeHandler(event)}
						submitDisabled = {this.state.submitDisabled}
						getStartedClickHandler = {this.getStartedClickHandler}
					/>
					{/*adding div to add minimum margin of 20px*/}
					<div style = {{paddingLeft: "20px", paddingRight:"20px"}}>
						<div className = {classes.StoreCards}>
							<TextField
								type = "text"
								alt
								inset 
                onChange = {this.updateSearch}
								placeholder = "Search for a store"/>
							<StoreCards placeholder = {this.state.loading} stores = {stores}/>
						</div>
					</div>
				</div>
			</React.Fragment>
	  );
 }
}
