import React from 'react';
import classes from './StoreCard.module.css';
import {Link} from 'react-router-dom';
import {Placeholder} from 'semantic-ui-react';
import 'semantic-ui-css/components/placeholder.min.css'
const storeCard = (props) => {
  if (props.placeholder) {
  	return (
  		<div className = {classes.StoreCard}>
				<Placeholder>
					<Placeholder.Image square/>
					<Placeholder.Header length='short'/>
					<Placeholder.Line length='medium' />
				</Placeholder>
			</div>
  	);
  }
  let data = props.store.data();
	return(
		<Link to = {"/stores/"+props.store.id} style = {{textDecoration:"none"}}>
			<div className = {classes.StoreCard}>
				<img src = {data.img}/>
				<h2> {data.name} </h2>
				<p> {data.address} {data.zip} </p>
			</div>
		</Link>
	);
}
export default storeCard;
