import React from 'react';
import classes from './Button.module.css'
const button = (props) => {
	let classArr = [classes.Button];
	if(props.alt) {
		classArr = [classes.Button, classes.Alt];
	}
	return(
		<button
			className = {classArr.join(' ')}
			onClick = {props.onClick}
			disabled = {props.disabled}
			>
				{props.children}
			</button>
	);
}
export default button;