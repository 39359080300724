import React from 'react';
import Button from '../../../../components/UI/Buttons/Button';
import TextField from '../../../../components/UI/TextField/TextField'
import classes from './ReservationManagementModal.module.css'
import Modal from '../../../../components/UI/Modal/Modal';
import Close from './Close/Close'
const ReservationManagementModal = (props) => {
	return(
		<Modal show = {props.showModal}>
			<div className = {classes.Modal}>
				<Close onClick = {props.closeClicked}/>
				<h1>{props.name}</h1>
				<h3>Party Size: {props.size}</h3>
				<Button onClick = {() => props.checkInClicked(props.id)}>
					Check In
				</Button>
				<br/>
				<Button onClick = {() => props.noShowClicked(props.id)}>
					Report No Show
				</Button>
			</div>
		</Modal>
	);
}
export default ReservationManagementModal;