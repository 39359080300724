import React from 'react';
import classes from './DropDown.module.css'
const dropDown = (props) => {
	let classArr = [classes.DropDown];
	if(props.alt) {
		classArr = [classes.DropDown, classes.Alt];
	}
	let options = props.options.map((option) => {
      let value;
      let text;
      if (Array.isArray(option)) {
        value = option[0];
        text = option[1];
      } else {
        value = option;
        text = option;
      }
		return(
			<option key = {value} value = {value}>{text}</option>
		);
	})
	return(
		<select className = {classArr.join(' ')} onChange = {props.onChange}>
			{options}
		</select>
	);
}
export default dropDown;
