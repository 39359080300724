import React from 'react';
import classes from './StorePage.module.css'
import Logo from '../../../components/UI/Logo/Logo'
import ReservationForm from './ReservationForm/ReservationForm'
import {fetchStore} from '../../../helpers/db';
export default class StorePage extends React.Component{
  constructor(props) {
    super(props);
    this.state = {store: null, notfound: false};
  }
	
	componentDidMount() {
		//load DB stuff here
    fetchStore(this.props.match.params.id).then((store) => {
      this.setState({store: store});
    }).catch((error) => {
      if (error == 404) this.setState({notfound: true});
    });
	}

 	render(){
    let text = null;
    if (this.state.notfound) text = "Not found";
    else if (this.state.store == null) text = "Loading store...";

    let store = this.state.store;
    let storeData = null;
    let precautions = [];
    if (store) {
      storeData = store.data();
      if (storeData.maxPeople) precautions.push(<li key="people">max number of people in store: {storeData.maxPeople}</li>);
      else if (storeData.masks) precautions.push(<li key="masks">All employees wear masks</li>);
    }

	  return(
		 	<React.Fragment>
		 		<Logo alt = {true}/>
        <div className = {classes.BackgroundCard}>
        {text
          ? <p>{text}</p>
          : <div className = {classes.StorePage}>
              <h1>{storeData.name}</h1>
              <h3>{storeData.address} {storeData.zip}</h3>
              {/*storeData image*/}
              <img src = {storeData.img} />
              <h3>About Us:</h3>
              <p style = {{textAlign: "left"}}>{storeData.description}</p>
              {precautions.length > 0 && 
                <React.Fragment>
                  <h3>Safety Precautions:</h3>
                  <ul style = {{textAlign:"left"}}>
                    {precautions}
                  </ul>
               </React.Fragment>
              }
              <ReservationForm store={store} />
            </div>
        }
        </div>
			</React.Fragment>
	  );
 }
}
