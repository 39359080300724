import React from 'react';
import Background from '../../../components/UI/Background/Background';
import {login, signup} from '../../../helpers/auth';
import {createStore, addUserToStore, createBooking} from '../../../helpers/db';
import SignupForm from '../FrontPage/SignupForm/SignupForm';
import Logo from '../../../components/UI/Logo/Logo';
import Aux from '../../../hoc/Aux';
import classes from './LearnMore.module.css';
import { Link } from 'react-router-dom'

class LearnMore extends React.Component{
  constructor(props) {
    super(props);
  }
  test = async () => {
    try {
      await login("idean1.labib@gmail.com", '123456');
      let store = await createStore("Test Store 1", 'pv5d7uCMidPTQYI14cEmhuBOAU92');
      await addUserToStore('GdAymuuM2XXo0jm4xXxZAVtVhUO2', store);
      let booking = await createBooking(new Date(2020, 4, 1, 15, 1), new Date(2020, 4, 1, 15, 30), store, '3604894097');
      console.log("Success!");
    } catch(error) {
      console.error(error);
    }
  }
 	render() {
 		return(
      <Aux>
        <Logo alt = {true}/>
        <div className = {classes.BackgroundCard}>
        <div className = {classes.LearnMore}>
          <h2>Welcome to Spaced</h2>
          <p>Spaced is a free, easy-to-use reservation system built for
           retail stores. Our mission is to create tools which facilitate safe shopping, without
            inconviniences.</p>
          <h2>What do I need?</h2>
          <p>Spaced is designed to work with minimal hardware. All you need is an internet
           connected device (mobile phone, laptop, etc.).</p>
          <h2>How do customers find my shop's Spaced Page?</h2>
          <p>Customers can search for shops by location or name. Additionally, you can add a Spaced button to your website:</p>
          <div style = {{textAlign: "center"}}>
            <Link className = {classes.BookButton}>
                <h3>Reserve on Spaced</h3>
            </Link>
          </div>
          <h2>Can I see a demo?</h2>
          <p>Absolutely! Check out what <Link to = "./places"> shoppers see</Link> and what <Link to = "./demo">stores see</Link>.</p>
          <h2>Questions, comments, suggestions</h2>
          <p>Reach out to <a href = "mailto:info@spacedshopping.com">info@spacedshopping.com</a>.</p>
          <h2>When will it be ready?</h2>
          <p>Launching Summer, 2020.</p>
        </div>
        <SignupForm alt = {true}/>
        </div>
      </Aux>
		);
 	}
}
export default LearnMore;
