import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyBhQj08SnHl0Ondn1psfLFgCGqSg_9mwt8",
    authDomain: "spaceshopping-b72a4.firebaseapp.com",
    databaseURL: "https://spaceshopping-b72a4.firebaseio.com",
    projectId: "spaceshopping-b72a4",
    storageBucket: "spaceshopping-b72a4.appspot.com",
    messagingSenderId: "180917232412",
    appId: "1:180917232412:web:e009fa044dfca7c90d90d9",
    measurementId: "G-95W4SE5CP3"
};

firebase.initializeApp(config);
firebase.analytics();
export const auth = firebase.auth;
export const db = firebase.firestore();
