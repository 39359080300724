import React from 'react';
import classes from './FrontPage.module.css';
import Background from '../../../components/UI/Background/Background';
import {login, signup} from '../../../helpers/auth';
import {createStore, addUserToStore, createBooking} from '../../../helpers/db';
import SignupForm from './SignupForm/SignupForm'
import Logo from '../../../components/UI/Logo/Logo';
import Aux from '../../../hoc/Aux'
import{Route, NavLink} from 'react-router-dom';
import { db } from '../../../services/firebase';

class FrontPage extends React.Component{
  constructor(props) {
    super(props);
  }

  //left for reference for updating db
  /*updateStores = () => {
    db.collection("stores").get().then((snapshot) => {
      snapshot.forEach((doc) => {
        let storeRef = doc.ref;
        let store = doc.data();

        let openTime = Math.floor(Math.random() * 5) / 2 + 7;
        let closeTime = Math.floor(Math.random() * 5) / 2 + 20;
        let masks = (Math.random() >= 0.5);
        let maxPeople = 40 - Math.floor(Math.random() * 10);
        let maxTime = Math.floor(Math.random() * 3 + 1)*15;

        storeRef.update({
          openTime: openTime,
          closeTime: closeTime,
          masks: masks,
          zip: '10022',
          maxPeople: maxPeople,
          maxTime: maxTime,
          description: "This is an example description for the store"
        });
      });
    });
  }*/

 	render() {
    //this.updateStores();
 		let link = <NavLink to = "/learn-more">Learn more</NavLink>;
    let shopperDemo = <NavLink to = "/places">demo</NavLink>;
    let storeDemo = <NavLink to = "/demo">demo</NavLink>;
 		return(
				<Background>
          <Aux>
            <Logo/>
            <div className  = {classes.FrontPage}>
    					<p> 
    						Get prepared to open by creating a safer shopping environment for your customers.
    						Spaced is a simple, free reservation system for retail that allows customers to
    						book times to shop. No lines, no fuss. {link}
                <br/>
                <br/>
                In the meantime, take a look at our {shopperDemo} for shoppers, and our {storeDemo} for shops.
    					</p>
            </div>
            <div style = {{
              paddingLeft: "20px",
              paddingRight: "20px"
            }}>
              <SignupForm/>
            </div>
          </Aux>
				</Background>
		);
 	}
}
export default FrontPage;
