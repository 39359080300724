import React from 'react';
const backArrow = (props) => {
	return(
		<svg onClick = {props.onClick} style = {props.style} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path d="M0 0h24v24H0z" fill="none"/>
			<path fill="#FF4F4F" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
		</svg>
	);
}

export default backArrow;