import React from 'react';
import classes from './StoreCards.module.css'
import StoreCard from './StoreCard/StoreCard'
const storeCards = (props) => {
	let cards = null;
	console.log(props.stores);
	if(props.placeholder) {
		cards = 
			[
				<StoreCard placeholder key = '1'/>,
				<StoreCard placeholder key = '2'/>,
				<StoreCard placeholder key = '3'/>,
				<StoreCard placeholder key = '4'/>,
				<StoreCard placeholder key = '5'/>,
				<StoreCard placeholder key = '6'/>,
				<StoreCard placeholder key = '7'/>,
				<StoreCard placeholder key = '8'/>
			];
	} else if(props.stores) {
			cards = props.stores.map((store) => {
			return(
				<StoreCard store={store} key = {store.id}/>
			);
		});
	}
	return(
		<div className = {classes.StoreCards}>
			{cards}
		</div>
	);
}
export default storeCards;
