import React from 'react';
import classes from './Logo.module.css'
import{Route, Link} from 'react-router-dom';
const logo = (props) => {
	if(props.alt === true) {
		return(
			<h1 className = {[classes.Logo, classes.Alt].join(' ')}><Link to = '/'>S P A C E D</Link></h1>
		);
	}
	return(
		<h1 className = {[classes.Logo, classes.Main].join(' ')}><Link to = '/'>S P A C E D</Link></h1>
	);
}
export default logo;